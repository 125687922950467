import utils from "@/stuff/Utils";
import { mapData } from "@/stuff/DataMapping";

export interface IRegionSupplier {
    regionID: number;
    supplierID: number;
    description: string;
    created: Date;
    createdByUserID: number;
    lastUpdated: Date;
    lastUpdatedByUserID: number;
    deleted: Date;
    deletedByUserID: number;
    buyerID: number;
    siteIDs: Array<number>;
}

export class RegionSupplier implements IRegionSupplier {

    constructor(data?: IRegionSupplier) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IRegionSupplier) {
        mapData(data, { root: () => this });
    }

    regionID: number = 0;
    supplierID: number = 0;
    description: string = "";
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: number = 0;
    deleted: Date = new Date(utils.emptyDateValue);
    deletedByUserID: number = 0;
    buyerID: number = 0;
    siteIDs: Array<number> = [];

    isNew: boolean = true;
    // get isNew() {
    //     return utils.isEmptyId(this.regionID) || utils.isEmptyId(this.supplierID);
    // }
}
