import { FollowUpType } from "@/model/Enums";
import { mapData } from "@/stuff/DataMapping";
import utils from "@/stuff/Utils";

export interface IFollowUp {
    id: number;
    paymentID: number;
    historyID: number;
    communicationID: number;
    type: FollowUpType;
    historyType: string;
    assignedToUserID: number;
    followUpDate: Date;
    createdDate: Date;
    createdByUserID: number;
    lastUpdated: Date;
    lastUpdatedByUserID: number;
    deleted: Date;
    deletedByUserID: number;
    supplierID: number;
    isBuyerCommunication: boolean;
}

export class FollowUp implements IFollowUp {

    constructor(data? : IFollowUp) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IFollowUp) {
        mapData(data, { root: () => this });
    }
    
    id: number = 0;
    paymentID: number = 0;
    historyID: number = 0;
    communicationID: number = 0;
    type: FollowUpType = 0;
    historyType: string = "";
    assignedToUserID: number = 0;
    followUpDate: Date = new Date(utils.emptyDateValue);
    createdDate: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: number = 0;
    deleted: Date = new Date(utils.emptyDateValue);
    deletedByUserID: number = 0;
    supplierID: number = 0;
    isBuyerCommunication: boolean = false;

}