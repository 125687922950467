import { TriState } from "./Enums";

export class CommunicationSearchParameters {
    pageNumber: number = 0;
    id: number = 0;
    supplierID: number = 0;
    isBuyer: TriState = TriState.UseDefault;
    typeID: number = 0;
    isClosed: TriState = TriState.UseDefault;
    userID: number = 0;
    buyerID: number = 0;
    hasBeenOpened: TriState = TriState.UseDefault;
}