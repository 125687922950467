
    import Vue from "vue";
    import Component from "vue-class-component";
    import ApiButton from "@/components/ApiButton.vue";
    import { Prop } from "vue-property-decorator";
    import { ButtonToggler } from "@/stuff/ButtonToggler";
    import * as toastr from "toastr";
    import apiClient from "@/stuff/ApiClient";
    import { mapData } from "@/stuff/DataMapping";
    import utils from "@/stuff/Utils";
    import { CertificateSearchParameters } from "@/model/CertificateSearchParameters";
    import { LookupItem, ILookupItem } from "@/model/LookupItem";
    import { Certificate, ICertificate, certificateTypeOptions } from "@/model/Certificate";
    import { CertificateType, LookupGroup, UserRole } from "@/model/Enums";
    import store from "@/store/store";

    @Component({
        components: { ApiButton }
    })
    export default class Certificates extends Vue {

        async mounted() {
            this.isSupplierZone = this.$router.currentRoute.path.toLowerCase().indexOf("supplierzone") > -1;
            this.isBuyerZone = this.$router.currentRoute.path.toLowerCase().indexOf("buyerzone") > -1;
            this.searchParameters.supplierID = this.supplierID;
            this.searchParameters.isSupplierZone = this.isSupplierZone;
            this.searchParameters.isBuyerZone = this.isBuyerZone;
            this.searchParameters.buyerID = this.isBuyerZone
                ? store.state.signedInUser == null ? 0 : +store.state.signedInUser.buyerID
                : 0;
            await Promise.all([
                this.loadCdmCategories(),
                this.loadSchemeNames(),
                this.loadBuyers(),
                this.search()
            ]);
            this.certificateTypeOptions = certificateTypeOptions("Choose...");
        }

        //
        // -- properties
        //

        @Prop({ required: true })
        private supplierID!: number;

        @Prop({ required: true })
        private supplierName!: string;

        isSupplierZone: boolean = false;
        isBuyerZone: boolean = false;

        searchParameters = new CertificateSearchParameters();
        certificateList: Array<Certificate> = [];
        readonly certificate = new Certificate();

        private cdmCategoryList: Array<ILookupItem> = [];
        private cdmCategoryOptions: Array<ILookupItem> = [];
        
        private schemeNameList: Array<ILookupItem> = [];
        private schemeNameOptions: Array<ILookupItem> = [];
        
        private certificateTypeOptions: Array<LookupItem> = [];

        buyerList: Array<ILookupItem> = [];

        // computed
        get dialogueTitle(): string {
            if (!this.certificate) return "- - -";
            return this.certificate.description;
        }

        get countText(): string {
            return this.certificateList.length.toString();
        }

        get isAdmin(): boolean { return (store.getters.userRole as UserRole) == UserRole.Admin; }

        //
        // -- methods
        //

        private async loadCdmCategories() {
            this.cdmCategoryList = await apiClient.get(`/api/lookup/search/${LookupGroup.CdmCategory}`);
            const choose = new LookupItem({ id: 0, description: "Please choose...", isArchived: false } as ILookupItem);
            this.cdmCategoryOptions = [choose, ...this.cdmCategoryList.filter((lu: ILookupItem) => lu.id > 0 && !lu.isArchived)];
        }

        private async loadSchemeNames() {
            this.schemeNameList = await apiClient.get(`/api/lookup/search/${LookupGroup.SchemeName}`);
            const choose = new LookupItem({ id: 0, description: "Please choose...", isArchived: false } as ILookupItem);
            this.schemeNameOptions = [choose, ...this.schemeNameList.filter((lu: ILookupItem) => lu.id > 0 && !lu.isArchived)];
        }

        private async loadBuyers() {
            this.buyerList = await apiClient.get(`/api/buyer/lookups?supplierID=${this.supplierID}&withusers=true`);
        }

        async view(certificateData: ICertificate) {
            this.certificate.update(certificateData);
            this.$bvModal.show("certificateDialogue");
        }

        cancel() {
            this.$bvModal.hide("certificateDialogue");
            utils.resetObject(this.certificate);
        }

        async download(event: Event) {
            const button = ButtonToggler.getButton(event);
            ButtonToggler.disableButton(button);
            const blob: Blob = await apiClient.get(`api/report/certificate?certificateID=${this.certificate.id}`);
            utils.downloadBlob(document, blob, `${certificateTypeOptions("").find(t => t.id == this.certificate.certificateType)?.description}-${utils.timeStampText()}.pdf`);       
            ButtonToggler.enableButton(button);
        }

        async saveCertificate(event?: Event) {
            const responseData: { newCount: number; newID: number } = await apiClient.post(`api/certificate/save`, this.certificate, event);
            this.certificate.id = responseData.newID;
            toastr.success(this.certificate.description, "Certificat Saved");
            this.$bvModal.hide("certificateDialogue");
            this.search();
        }

        // 'delete' is a reserved word
        async deleteItem(event: Event) {
            if (this.certificate.isNew) return;
            const shouldDelete: boolean = await this.$bvModal.msgBoxConfirm("Do you want to delete '" + this.certificate.description + "'?", {
                title: "Delete",
                okVariant: "danger",
                okTitle: "Yes, delete!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldDelete) return;
            await apiClient.post("/api/certificate/delete", this.certificate, event);
            toastr.warning("Deleted");
            this.$bvModal.hide("certificateDialogue");
            this.search();
        }

        async archive(event: Event) {
            if (this.certificate.isNew) return;
            
            const shouldArchive: boolean = await this.$bvModal.msgBoxConfirm("Do you want to archive '" + this.certificate.description + "'?", {
                title: "Archived",
                okVariant: "danger",
                okTitle: "Yes, archive!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldArchive) return;

            await apiClient.get(`/api/certificate/${this.certificate.id}/archive`)
            toastr.warning("Archived");
            this.$bvModal.hide("certificateDialogue");
            this.search();
        }

        async restore(event: Event) {
            if (this.certificate.isNew) return;

            const shouldArchive: boolean = await this.$bvModal.msgBoxConfirm("Do you want to restore '" + this.certificate.description + "'?", {
                title: "Restore",
                okVariant: "primary",
                okTitle: "Yes, restore!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldArchive) return;

            await apiClient.get(`/api/certificate/${this.certificate.id}/restore`)
            toastr.success("Restored");
            this.$bvModal.hide("certificateDialogue");
            this.search();
        }

        private async search(event?: Event) {
            const responseData = await apiClient.post("/Api/Certificate/Search", this.searchParameters, event);
            this.certificateList = mapData<Array<Certificate>>(responseData, { root: (c) => new Certificate(c) });
            this.$emit("certificateCountChanged", this.certificateList.length); // update tab count badge in parent
        }
    }
