
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ApiButton from "@/components/ApiButton.vue";
import apiClient from "@/stuff/ApiClient";
import fileDownload from "@/stuff/FileDownload";
import { IItemWithFile } from '@/model/IItemWithFile';
import { IFileUploadResponse } from "@/model/IFileUploadResponse";
import * as toastr from "toastr";
import utils from "@/stuff/Utils";

@Component({
    components: { ApiButton }
})
export default class FileUpload extends Vue {

    chosenFile: File|null = null;

    isUploading: boolean = false;

    @Prop({ required: true })
    private itemWithFile!: IItemWithFile;

    @Prop({ default: true })
    private required!: false;

    @Prop({ default: false })
    private canDelete!: false;

    private get fileState() {
        return this.required ? Boolean(this.chosenFile) : null;
    }

    private get iconUrl(): string {
        if(!this.itemWithFile.hasFile) return apiClient.resolveUrl("api/file/icon?extension=nul");
        const filename = this.itemWithFile.filename;
        const dotAt = filename.lastIndexOf(".");
        const extension = dotAt === -1 || dotAt >= filename.length - 1 ? "" : filename.substr(dotAt + 1).toLowerCase();
        return apiClient.resolveUrl(`api/file/icon?extension=${extension}`);
    }

    private get shouldShowExistingFile(): boolean {
        return this.itemWithFile.hasFile && !this.isUploading;
    }

    private get shouldShowNoFile(): boolean {
        return !this.itemWithFile.hasFile && !this.isUploading;
    }

    private get shouldShowUploadWaiting(): boolean {
        return this.isUploading;
    }

    private get shouldShowDate(): boolean {
        return utils.hasDateValue(this.itemWithFile.lastUpdated);
    }

    async clear(event: Event) {
        this.chosenFile = null;
        utils.resetObject(this.itemWithFile);
    }

    // Fires when file is chosen by user
    // NB - in multi-file mode, file would be an array
    async onInput(file: File) {
        if(!file) {
            toastr.info("No file chosen");
            return; 
        }
        if(!this.chosenFile) {
            toastr.info("No file chosen");
            return; 
        }                
        const formData = new FormData();
        formData.append("formFile", this.chosenFile);

        this.isUploading = true;

        try {
            const response: IFileUploadResponse = await apiClient.postNonJson("/api/file/upload", formData);
            
            if (!response.success) {
                this.isUploading = false;
                toastr.error(response.errorMessage, "Upload failed");
                return;
            }

            this.itemWithFile.httpContentType = response.httpContentType;
            this.itemWithFile.originalFilename = response.originalFilename;
            this.itemWithFile.filename = response.filename;
            this.itemWithFile.size = response.size;
            this.itemWithFile.lastUpdated = new Date(utils.emptyDateValue);
            this.itemWithFile.uploadedDate = new Date();
            this.isUploading = false;
            this.$emit("uploaded", this.itemWithFile);
        } catch (error) {
            this.isUploading = false;
            toastr.error("Upload failed");
        }        
    }

    async download() {
        fileDownload.download(this.itemWithFile);
    }
}
