
    import Vue from "vue";
    import Component from "vue-class-component";
    import ApiButton from "@/components/ApiButton.vue";
    import DatePicker from "@/components/DatePicker.vue";
    import FileUpload from "@/components/FileUpload.vue";
    import { Validations } from "vuelidate-property-decorators";
    import { Watch } from "vue-property-decorator";
    import { Prop } from "vue-property-decorator";
    import * as toastr from "toastr";

    import store from "@/store/store";
    import apiClient from "@/stuff/ApiClient";
    import { ILookupItem } from "@/model/LookupItem";
    import { IPayment, Payment } from "@/model/Payment";
    import {PaymentSearchParameters } from "@/model/PaymentSearchParameters";
    import utils from "@/stuff/Utils";
    import { TriState, UserRole, DocumentCategory } from "@/model/Enums";
    import { FollowUp } from "@/model/FollowUp";
    import { UserSearchParameters } from "@/model/UserSearchParameters";
    import fileDownload from "@/stuff/FileDownload";

    @Component({
        components: { ApiButton, DatePicker, FileUpload }
    })
    export default class Region extends Vue {

        async mounted() {
            await Promise.all([
                this.getAwaitingPaymentUserID(),
                this.getPaymentReceivedUserID(),
                this.getAccountsManagerUserID()
            ])

            store.dispatch("loadPaymentTypeList");
            store.dispatch("loadPaymentMethodList");
            store.dispatch("loadUserList");
            this.isSupplierZone = this.$router.currentRoute.path.toLowerCase().indexOf("supplierzone") > -1;
            this.isBuyerZone = this.$router.currentRoute.path.toLowerCase().indexOf("buyerzone") > -1;
            this.searchParameters.isDeleted = TriState.False;
            this.searchParameters.supplierID = this.supplierID;
            this.getAssignmentOptions()
            this.load();
        }

        //
        // -- properties
        //

        @Prop({ default: 0 })
        private supplierID!: number;

        isWaiting: boolean = true;

        readonly searchParameters = new PaymentSearchParameters();
        readonly payment = new Payment();
        paymentList: Array<Payment> = [];

        isSupplierZone: boolean = false;
        isBuyerZone: boolean = false;

        awaitingPaymentUserID: number = 0;
        paymentReceivedUserID: number = 0;
        accountsManagerUserID: number = 0;

        get paymentTypeList(): Array<ILookupItem> { return this.$store.state.paymentTypeList; }
        get paymentTypeOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.paymentTypeList); }
        get paymentTypeSearchOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.paymentTypeList, "Any type..."); }

        get paymentMethodList(): Array<ILookupItem> { return this.$store.state.paymentMethodList; }
        get paymentMethodOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.paymentMethodList); }
        
        get userList(): Array<ILookupItem> { return this.$store.state.userList; }
        assignToOptions: Array<ILookupItem> = [];

        get paymentDialogueTitle(): string {
            if (!this.payment) return "- - -";
            if (this.payment.isNew) return "New Payment";
            return "Edit " + this.payment.description;
        }

        get canDeletePayment() {
            return !this.payment.isNew && !utils.hasDateValue(this.payment.deleted);
        }

        get savePaymentButtonText() {
            return utils.hasDateValue(this.payment.deleted) ? "Save Payment and Un-archive" : "Save Payment";
        }

        get hasFollowUp(): boolean {
            return this.payment.followUp != null;
        }
        set hasFollowUp(value: boolean) {
            if (value) {
                this.payment.followUp = new FollowUp();
                this.payment.followUp.followUpDate = new Date();
                this.payment.followUp.assignedToUserID = this.accountsManagerUserID;
            } 
            else this.payment.followUp = null;
        }

        //
        // -- watchers
        //

        @Watch("supplierID")
        onSupplierIdChanged() {
            this.searchParameters.supplierID = this.supplierID;
            this.load();
        }

        @Watch("searchParameters.typeID")
        onTypeIdChanged() {
            this.refreshPayments();
        }

        @Watch("payment.requestForPayment")
        onRequestForPaymentChanged(value: Date) {
            if (this.isWaiting || !utils.hasDateValue(value)) return;
            if (!this.hasFollowUp) this.hasFollowUp = true;
            window.setTimeout(() => {
                this.payment.followUp!.followUpDate = value;
                this.payment.followUp!.assignedToUserID = this.awaitingPaymentUserID;
            }, 50);
        }

        @Watch("payment.paidWhen")
        onPaidWhenChanged(value: Date) {
            if (this.isWaiting || !utils.hasDateValue(value)) return;
            if (!this.hasFollowUp) this.hasFollowUp = true;
            window.setTimeout(() => {
                this.payment.followUp!.followUpDate = value;
                this.payment.followUp!.assignedToUserID = this.paymentReceivedUserID;
            }, 50);
        }

        //
        // -- methods
        //

        private async getAwaitingPaymentUserID() {
            this.awaitingPaymentUserID = await apiClient.get("api/lookup/awaitingPaymentID");
        }

        private async getPaymentReceivedUserID() {
            this.paymentReceivedUserID = await apiClient.get("api/lookup/paymentReceivedID");
        }

        private async getAccountsManagerUserID() {
            this.accountsManagerUserID = await apiClient.get("api/lookup/accountsManagerID");
        }

        iconUrl(payment: Payment): string {
            if(!payment.document.hasFile) return apiClient.resolveUrl("api/file/icon?extension=nul");
            const filename = payment.document.filename;
            const dotAt = filename.lastIndexOf(".");
            const extension = dotAt === -1 || dotAt >= filename.length - 1 ? "" : filename.substr(dotAt + 1).toLowerCase();
            return apiClient.resolveUrl(`api/file/icon?extension=${extension}`);
        }

        filename(payment: Payment): string {
            return payment.document.hasFile ? payment.document.originalFilename : "No file uploaded";
        }

        async download(payment: Payment) {
            fileDownload.download(payment.document);
        }

        showFollowUpIcon(payment: Payment): boolean {
            return payment.followUp != null;
        }
        followUpIcon(payment: Payment): string {
            if (this.showFollowUpIcon(payment) && payment.followUp!.followUpDate < new Date()) return "fas fa-alarm-exclamation";
            else return "fas fa-alarm-clock";
        }
        followUpColour(payment: Payment): string {
            if (this.showFollowUpIcon(payment) && payment.followUp!.followUpDate < new Date()) return "red";
            else return "black";
        }
        followUpTitle(payment: Payment): string {
            if (!this.showFollowUpIcon(payment)) return "";
            else if (utils.isEmptyId(payment.followUp!.assignedToUserID)) return `Follow up by ${utils.dateText(payment.followUp!.followUpDate)}`;
            else return `Follow up by ${utils.dateText(payment.followUp!.followUpDate)}, assigned to ${utils.lookupDescription(payment.followUp!.assignedToUserID, this.userList)}`;
        }

        async getAssignmentOptions() {            
            const options = [];
            const params = new UserSearchParameters();
            params.isDeleted = TriState.False;
            params.isDormant = TriState.False;

            params.role = UserRole.Admin;
            let response = await apiClient.post("/api/user/searchLookups", params)
            options.push(...response)

            params.role = UserRole.Cqms;
            response = await apiClient.post("/api/user/searchLookups", params)
            options.push(...response)

            options.sort((a,b) => (a.description.toLowerCase() > b.description.toLowerCase()) ? 1 : ((b.description.toLowerCase() > a.description.toLowerCase()) ? -1 : 0));

            this.assignToOptions = utils.selectOptions(options, "Unassigned");
        }

        async load() {
            this.$v.$reset();
            this.payment.followUp = null;
            utils.resetObject(this.payment);
            this.paymentList = [];
            await this.refreshPayments();
        }

        async refreshPayments() {
            this.payment.followUp = null;
            utils.resetObject(this.payment);
            this.isWaiting = true;
            const responseData = await apiClient.post("api/payment/search", this.searchParameters);
            this.paymentList = responseData.list.map((c: IPayment) => new Payment(c));
            this.$emit("paymentCountChanged", responseData.count); // update tab count badge in parent
            this.isWaiting = false;
        }

        paidText(payment: Payment): string {
            return utils.hasDateValue(payment.paidWhen) 
                ? `PAID - ${utils.dateText(payment.paidWhen)}`
                : "UNPAID"
        }

        async editPayment(paymentData: IPayment) {
            if (this.isSupplierZone || this.isBuyerZone) return;
            this.isWaiting = true;

            this.payment.update(paymentData);
            // reset any validation
            this.$v.$reset();
            // show the dialogue
            this.$bvModal.show("paymentDialogue");
            // refresh from server
            const serverPaymentData = await apiClient.get(`api/payment/Load?id=${paymentData.id}`);
            this.payment.update(serverPaymentData);

            window.setTimeout(() => {
                this.isWaiting = false;
            }, 50)
        }

        editNewPayment() {
            this.payment.followUp = null;
            utils.resetObject(this.payment);
            this.$v.$reset();
            this.payment.supplierID = this.supplierID;
            this.$bvModal.show("paymentDialogue");
        }

        cancelPayment() {
            this.$bvModal.hide("paymentDialogue");
            this.payment.followUp = null;
            utils.resetObject(this.payment);
        }

        async savePayment(event: Event) {
            // 'touch' all the fields to activate the validation messages
            this.$v.$touch();
            if (this.$v.payment.$invalid) {
                toastr.info("Please fix the highlighted errors", "Validation errors");
                return;
            }
            this.payment.document.category = DocumentCategory.Invoice;
            const response: { newID: number } =  await apiClient.post("/api/payment/save", this.payment, event);
            if (this.payment.isNew) {
                this.payment.id = response.newID;
            }
            toastr.success("Saved");           
            this.$bvModal.hide("paymentDialogue");
            await this.refreshPayments();
        }

        async deletePayment(event: Event) {
            if (this.payment.isNew) return;
            const shouldDelete: boolean = await this.$bvModal.msgBoxConfirm("Do you want to delete '" + this.payment.description + "'?", {
                title: "Delete Payment",
                okVariant: "danger",
                okTitle: "Yes, delete!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldDelete) return;
            await apiClient.post("/api/payment/delete", this.payment, event);
            toastr.warning("Deleted");
            this.$bvModal.hide("paymentDialogue");
            await this.refreshPayments();
        }

        currencyFormatter(value: string) {
            let num = +value;
            if(isNaN(num)) num = 0;
            //return num.toLocaleString(undefined, {maximumFractionDigits:2});
            return num.toFixed(2);
        }

        @Validations()
        validations() {
            const validations = {
                payment: {} as any // eslint-disable-line @typescript-eslint/no-explicit-any
            };
            validations.payment.followUp = {} as any;
            validations.payment.paymentTypeID = { isNonZero: (value: number) => +value > 0 };
            validations.payment.followUp.followUpDate =  { isValid: (value: Date) => !this.hasFollowUp || utils.hasDateValue(value) };
            return validations;
        }
    }
