
    import Vue from "vue";
    import Component from "vue-class-component";
    import ApiButton from "@/components/ApiButton.vue";
    import UserDialogue from "@/components/UserDialogue.vue"
    import { Prop } from "vue-property-decorator";
    import apiClient from "@/stuff/ApiClient";
    import { IUser, User } from "@/model/User";
    import { ISendPasswordResetRequest } from "@/model/ISendPasswordResetRequest";
    import { ISendPasswordResetResponse } from "@/model/ISendPasswordResetResponse";
    import * as toastr from "toastr";

    @Component({
        components: { ApiButton, UserDialogue }
    })
    export default class Users extends Vue {

        mounted() {
            this.isBuyerZone = this.$router.currentRoute.path.toLowerCase().indexOf("buyerzone") > -1;
            this.isSupplierZone = this.$router.currentRoute.path.toLowerCase().indexOf("supplierzone") > -1;
            this.load();
        }

        // properties

        @Prop({ default: 0 }) private supplierID!: number;
        private isBuyerZone: boolean = false;
        private isSupplierZone: boolean = false;
        private users: Array<User> = [];

        // methods

        private rowClass(user: IUser): string {
            return user.deleted ? "deleted" : "";
        }

        private async load() {
            this.users = [];
            const response = await apiClient.get(`/api/supplier/users?supplierID=${this.supplierID}`);
            this.users.push(...response.map((u: IUser) => new User(u)));
            this.$emit("userCountChanged", this.users.length);
        }

        private async sendReminder(user: User) {
            const shouldSend: boolean = await this.$bvModal.msgBoxConfirm("Do you want to send a reset link to " + user.fullname, {
                title: "Password Reset",
                okVariant: "danger",
                okTitle: "Yes, send!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldSend) return;

            const request: ISendPasswordResetRequest = {
                email: user.email,
                isSelfInitiated: false 
            }
            const response = await apiClient.post("/Api/Authentication/sendPasswordReset", request) as ISendPasswordResetResponse;
            if(response.isSuccess) {
                toastr.success(user.forename, "Sent");
            }
            else {
                toastr.error(response.message, "FAILED"); // maybe use message box rather than toast?
            }           
        }

        addUser() {
            const dialog = (this.$refs.userDialogue as UserDialogue);
            dialog.addSupplierUser(this.supplierID);
        }

        editUser(user: IUser) {
            if (this.isBuyerZone || this.isSupplierZone) return;
            const dialog = (this.$refs.userDialogue as UserDialogue);
            dialog.edit(user);
        }

    }
