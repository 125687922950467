import utils from "@/stuff/Utils";
import { mapData } from "@/stuff/DataMapping";
import { FollowUp } from "@/model/FollowUp";
import { IDocument, Document } from "./Document";
import { DocumentVisibility } from "./Enums";

export interface IPayment {
    id: number;
    supplierID: number;
    paymentTypeID: number;
    amount: number;
    requestForPayment: Date;
    paidWhen: Date;
    paymentMethodID: number;
    notes: string;
    created: Date;
    createdByUserID: number;
    lastUpdated: Date;
    lastUpdatedByUserID: number;
    deleted: Date;
    deletedByUserID: number;
    document: IDocument;
    followUp: FollowUp|null;
}

export class Payment implements IPayment {

    constructor(data?: IPayment) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IPayment) {
        mapData(data, { 
            root: () => this,
            document: () => new Document(),
            followUp: () => new FollowUp()
        });
    }

    id: number = 0;
    supplierID: number = 0;
    paymentTypeID: number = 0;
    amount: number = 0;
    requestForPayment: Date = new Date(utils.emptyDateValue);
    paidWhen: Date = new Date(utils.emptyDateValue);
    paymentMethodID: number = 0;
    notes: string = "";
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: number = 0;
    deleted: Date = new Date(utils.emptyDateValue);
    deletedByUserID: number = 0;
    followUp: FollowUp|null = null;
    document: Document = new Document();

    get isNew() {
        return utils.isEmptyId(this.id);
    }

    get isDeleted() {
        return utils.hasDateValue(this.deleted);
    }
    
    get description() {
        return `Payment for £${this.amount.toFixed(2)} on ${utils.dateText(this.paidWhen)}`;
    }
}
