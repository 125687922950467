import utils from "@/stuff/Utils";
import { mapData } from "@/stuff/DataMapping";
import { Document as ModelDocument, IDocument } from "@/model/Document";
import { FollowUp } from "@/model/FollowUp";

export interface ICommunication {
    id: number;
    supplierID: number;
    typeID: number;
    documentIDs: Array<string>;
    isBuyer: boolean;
    details: string;
    buyerIDs: Array<number>;
    notifyUserIDs: Array<number>;
    alertSent: Date;
    openedDate: Date;
    openedByUserID: number;
    closed: Date;
    created: Date;
    createdByUserID: number;
    lastUpdated: Date;
    lastUpdatedByUserID: number;
    followUp: FollowUp|null;
    regions: string;
    
    documents: Array<IDocument>;
}

export class Communication implements ICommunication {

    constructor(data?: ICommunication) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: ICommunication) {
        mapData(data, { 
            root: () => this,
            followUp: () => new FollowUp(),
            documents: () => new ModelDocument(),
        });
    }

    id: number = 0;
    supplierID: number = 0;
    typeID: number = 0;
    documentIDs: Array<string> = [];
    isBuyer: boolean = false;
    details: string = "";
    buyerIDs: Array<number> = [];
    notifyUserIDs: Array<number> = [];
    alertSent: Date = new Date(utils.emptyDateValue);
    openedDate: Date = new Date(utils.emptyDateValue);
    openedByUserID: number = 0;
    closed: Date = new Date(utils.emptyDateValue);
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: number = 0;
    followUp: FollowUp|null = null;
    regions: string = "";

    documents: Array<ModelDocument> = [];

    get isNew() {
        return utils.isEmptyId(this.id);
    }

    get isClosed() {
        return utils.hasDateValue(this.closed);
    }

    public get hasBeenOpened() {
        return utils.hasDateValue(this.openedDate);
    }

    get description() {
        return `communication sent ${utils.dateText(this.created)}`;
    }

}