import utils from "@/stuff/Utils";
import { mapData } from "@/stuff/DataMapping";
import { IDocument, Document } from "./Document";
import { DocumentVisibility } from "./Enums";

export interface ISupplierDocument {
    supplierID: number;
    documentID: string;
    visibility: DocumentVisibility;
    document: IDocument;
}

export class SupplierDocument implements ISupplierDocument {

    constructor(data?: ISupplierDocument) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: ISupplierDocument) {
        mapData(data, { 
            root: () => this,
            document: () => this.document
        });
    }
  
    supplierID: number = 0;
    documentID: string = "";
    visibility: DocumentVisibility = DocumentVisibility.None;
    document: Document = new Document();

    get description() {
        return this.document ? this.document.name : "(null document)";
    }
    set description(value: string) {
        if(this.document == null) this.document = new Document();
        this.document.name = value;
    }

    get isNew() {
        return utils.isEmptyId(this.document.id);
    }
}
